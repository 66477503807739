body {
  background-color: #282727;
}

.container {  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "Header"
    "Board";
  font-family: 'Roboto Condensed', sans-serif;
}

.Header {  display: grid;
  padding-top: 5px;
  padding-left: 25px;
  padding-bottom: 5px;
  padding-right: 25px;
  grid-template-columns: 30px 1fr 30px;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "MenuLeft Title MenuRight";
  grid-area: Header;
  border-bottom: 1px solid #4D4D4D;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
}

.MenuRight {
  justify-self: end;
  grid-area: MenuRight;
  align-self: center;
  color: #BFBFBF;
}

.MenuLeft {
  justify-self: start;
  grid-area: MenuLeft;
  align-self: center;
  color: #BFBFBF;
}

.Title {
  justify-self: center;
  grid-area: Title;
  font-weight: normal;
  font-size: 44px !important;
  align-self: center;
  color: #BFBFBF;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}

.Board {  display: grid;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  grid-auto-columns: 1fr;
  gap: 10px 20px;
  grid-auto-flow: row;
  justify-content: center;
  align-content: start;
  grid-area: Board;
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
  box-sizing: border-box
}

.Row {  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 60px;
  gap: 0px 12px;
  grid-auto-flow: row;
  grid-template-areas:
    ". .";
  font-size: 36px;
  background: #353535;
  border-radius: 8px;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.25));
}

.WordLeft{
  justify-self: right;
  align-self: center;
  text-align: right;
}

.WordRight{
  justify-self: left;
  align-self: center;
  text-align: left;
}

.Guess{
  font-weight: lighter;
  color: #585858;
}

.Hint{
  font-weight: bold;
  color: #BFBFBF;
}

.Active{
  background: #6B6B6B;
}

.Correct{
  background: #3AA151;
  color: #BFBFBF;
}

html, body , .container {
  height: 100%;
  margin: 0;
}

input,
textarea {
  background: inherit;
  border: none;
  outline: 0;
  cursor: text;
  color: #BFBFBF;
  font-family: inherit;
  font-size: inherit;
  width: 95%;
  font-weight: normal;
  padding: 0;
  text-align: inherit;
}
textarea {
  resize: none;
}

::placeholder {
  color: #353535;
}

.Tooltip { 
  height: 30px;
  font-size: 15px;
  /* background: #353535; */
  color:#BFBFBF;
  line-height: 30px;
  text-align: center;
  border-radius: 8px;
  /* filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.25)); */
}

.Instructions {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  color: #BFBFBF;
  text-align: justify;
  margin: 0 auto;
  max-width: 600px;
}

.Statistics {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  color: #BFBFBF;
  text-align: justify;
  margin: 0 auto;
  max-width: 600px;
}

button.icon {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0 4px;
}

.ShareButton {
  font-size: 30px;
  background: #6B6B6B;
  color: #BFBFBF;;
  border-radius: 8px;
  border: none;
  font-family: inherit;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
}

a {
  color: #BFBFBF;
}
/* For presentation only, no need to copy the code below */
/* 
.container * {
  border: 1px solid red;
  position: relative;
}

.container *:after {
  content:attr(class);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
} */
